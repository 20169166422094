import React from 'react'
import { Button } from 'antd'
import { Link } from 'react-router-dom';

import '../css/index.css'

class Header extends React.Component {

    state = {
        show: false,
    }

    // 
    ckickHeader = () => {
        if (this.state.show) {
            this.refs.navShow.style.display = 'none'
        } else {
            this.refs.navShow.style.display = 'block'
        }
        this.setState({
            show: !this.state.show
        })
    }

    render() {
        const { history } = this.props
        let active = history.location.pathname
        return (
            <div className="header" style={{ background: '#2c4fcd', height: 64, lineHeight: '64px', position: 'relative' }}>
                <div className="container">
                    <Link to="/">
                        <img src={require('../assets/image/logo1.png')} style={{ maxWidth: 200,verticalAlign: 'middle',borderStyle: 'none', height: 34 }} />
                    </Link>
                    <div id="hamburger" onClick={this.ckickHeader}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                    <ul className="nav-left" ref="navShow">
                        <li className={(active === '/' || active.indexOf('home') > -1) ? 'active' : 'noactive'}><Link to="/">原理</Link></li>
                        <li className={active.indexOf('vision') > -1 ? 'active' : 'noactive'}><Link to="/vision">视觉</Link></li>
                        <li className={active.indexOf('/speech') > -1 ? 'active' : 'noactive'}><Link to="/speech">语音</Link></li>
                        <li className={active.indexOf('/text') > -1 ? 'active' : 'noactive'}><Link to="/text">文本</Link></li>
                        <li className={active.indexOf('/art') > -1 ? 'active' : 'noactive'}><Link to="/art">艺术</Link></li>
                    </ul>
                </div>
            </div>
        );
    }
}

export default Header;
