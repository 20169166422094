import React from 'react';
import { Button } from 'antd'
import '../css/home.css'

import Header from '../compontents/Header';
import Card from '../compontents/Card';

const imgStyle = { objectFit: 'cover', width: '100%', height: '100%' }

class Vision extends React.Component {

    render() {

        return (
            <div className="vision">
                <Header history={this.props.history}></Header>
                <div style={{ height: 380, width: '100%', position: 'relative' }}>
                    <img src={require('../assets/image/background.jpg')} style={imgStyle} />
                    <div style={{ position: 'absolute', top: "calc(50% - 100px)", width: '100%', textAlign: 'center', color: '#fff', padding: '0 20px' }}>
                        <h1 style={{ fontSize: '4rem', color: '#fff' }}>计算机视觉</h1>
                        <p style={{ fontSize: '2.164rem' }}>让电脑“感知”人类能看到的影像，令AI能根据视频、图像等做出决策。</p>
                    </div>
                </div>
                <Card data={this.props.data} history={this.props.history} ></Card>
            </div>
        );
    }
}

export default Vision;
